















































































































import Vue from 'vue';
import {Backend} from "api-frontend";
import {FamilyWithMembers} from "mp-common/src/types/entities/family";
import {familyApi} from "mp-common/src/types/api/familyApi";
import {hasRoles} from "mp-common/src/helper/permissions";
import {Roles, User} from "mp-common/src/types/entities/user";
import {users} from "mp-common/src/types/api/usersApi";

export default Vue.extend({
  name: 'ModifyFamilies',
  props: {
    user: {} as () => User,
    backend: {
      type: Backend,
    }
  },
  data() {
    return {
      families: [] as FamilyWithMembers[],
      newFamily: {
        name: "",
        members: [] as Omit<User, "password">[]
      },
      alterFamily: {
        id: 0,
        name: "",
        members: [] as Omit<User, "password">[]
      },
      selectedUser: "",
      users: [] as Omit<User, "password">[],
      requesting: false,
      showSuccess: false,
      showAddFamilyDialog: false,
      showAlterFamilyDialog: false,
    }
  },
  created() {
    this.fetchUsers();
    this.fetchFamilies();
  },
  methods: {
    async fetchUsers() {
      this.users = await this.backend.query(users.getAll, undefined) ?? [];
    },
    async fetchFamilies() {
      this.families = await this.backend.query(familyApi.getAll, undefined) ?? [];
    },
    async addFamily() {
      if (this.newFamily.name.length === 0 || this.newFamily.members.length === 0) {
        return;
      }
      this.requesting = true;
      const result = await this.backend.query(familyApi.add, {
        name: this.newFamily.name,
        members: this.newFamily.members.map((member) => member.id)
      });
      if (result?.success) {
        this.showAddFamilyDialog = false;
        this.showSuccess = true;
        this.newFamily.members = [];
        this.newFamily.name = "";
        await this.fetchFamilies();
      }
      this.requesting = false;
    },
    async modifyFamily() {
      if (this.alterFamily.name.length === 0 || this.alterFamily.members.length === 0 || this.alterFamily.id === 0) {
        return;
      }
      this.requesting = true;
      const result = await this.backend.query(familyApi.alter, {
        familyId: this.alterFamily.id,
        replacement: {
          name: this.alterFamily.name,
          members: this.alterFamily.members.map((member) => member.id)
        }
      });
      if (result?.success) {
        this.showAlterFamilyDialog = false;
        this.showSuccess = true;
        await this.fetchFamilies();
      }
      this.requesting = false;
    },
    async deleteFamily(id: number) {
      this.requesting = true;
      const result = await this.backend.query(familyApi.remove, {
        familyId: id
      });
      if (result?.success) {
        this.showSuccess = true;
        await this.fetchFamilies();
      }
      this.requesting = false;
    },
    showAlterFamily(family: FamilyWithMembers) {
      this.alterFamily = {...family, name: family.name ?? ""};
      this.showAlterFamilyDialog = true;
    },
    addMemberToAlter() {
      const user = this.users.find((user) => this.selectedUser.includes(user.lastName) && this.selectedUser.includes(user.firstName));
      if (user) {
        this.alterFamily.members.push(user);
        this.selectedUser = "";
      }
    },
    addMemberToNew() {
      const user = this.users.find((user) => this.selectedUser.includes(user.lastName) && this.selectedUser.includes(user.firstName));
      if (user) {
        this.newFamily.members.push(user);
        this.selectedUser = "";
      }
    },
    removeMemberFromAlter(userId: number) {
      this.alterFamily.members = this.alterFamily.members.filter((member) => member.id !== userId);
    },
    removeMemberFromNew(userId: number) {
      this.newFamily.members = this.newFamily.members.filter((member) => member.id !== userId);
    },
    hasPlanCreatorRole() {
      return hasRoles(this.user, Roles.PLAN_GENERATOR);
    },
    getNotInAlterUsers() {
      return this.users.filter((user) => !this.alterFamily.members.includes(user)).sort((first, next) => first.lastName.localeCompare(next.lastName))
    },
    getNotInAddUsers() {
      return this.users.filter((user) => !this.newFamily.members.includes(user)).sort((first, next) => first.lastName.localeCompare(next.lastName))
    }
  }
})
